<template>
    <!-- Add to playlist modal -->
    <div class="modal fade show d-block" id="addPlaylistModal" tabindex="-1" role="dialog" aria-labelledby="addPlaylistModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
             إضافة إلى قائمة تشغيل
          </div>
          <div class="modal-body">
              
            <div v-if="$apollo.queries.me.loading" class="text-center"><i class="la la-spin la-spinner la-2x"></i></div>

             <div class="playlist-item px-4 py-3 border-bottom border-light" v-for="playlist in me.playlists" :key="playlist.id">
               <div class="d-flex">
                 <label class="col cursor-pointer ps-2" :for="'playlist-item-' + playlist.id">{{ playlist.name }}</label>
                 <div class="ps-3 d-flex flex-column justify-content-center"><input type="checkbox" v-model="checkPlaylistIDs" :id="'playlist-item-' + playlist.id" :value="playlist.id" class="form-check-input"></div>
               </div>
             </div>
             <div class="playlist-item px-4 py-3" v-if="!newPlaylistShow">
               <div class="d-flex text-success cursor-pointer" @click="newPlaylistShow = true">
                 <div class="col ps-2">لقائمة تشغيل جديدة</div>
                 <div class="ps-1 d-flex flex-column justify-content-center"><i class="la la-plus la-lg"></i></div>
               </div>
             </div>
             <div class="playlist-item px-4 mb-2" v-else>
               <div class="d-flex">
                 <div class="col"><input type="text" v-model="newPlaylistName" class="form-control form-control-sm" :autofocus="newPlaylistShow" id="playlist-item-1"></div>
                 <div class="ps-3 pt-2"><input type="checkbox" v-model="newPlaylistInputIsActive" id="playlist-item-1" class="form-check-input" checked></div>
               </div>
             </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('close')">إلغاء</button>
            <button type="button" class="btn btn-success" @click="onSave" :disabled="isLoading">
                <span v-if="isLoading" class="text-center"><i class="la la-spin la-spinner"></i></span>
                حفظ
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import gql from "graphql-tag"

export default {
    props: ['track'],

    data() {
        return {
            newPlaylistShow: false,
            me: {},
            checkPlaylistIDs: [],
            newPlaylistInputIsActive: true,
            newPlaylistName: 'قائمة تشغيل جديدة',
            isLoading: false,
            playlists: [],
        }
    },

    // computed: {
        // playlists () {
        //     return  this.me.playlists ? this.me.playlists: [];
        // }
    // },

    watch: {
        me (val) {
            if(val.playlists) {
                val.playlists.forEach(playlist => {
                    let tracksIDs = playlist.tracks.map(t => t.id)
                    if (tracksIDs.includes(this.track.id)) {
                        this.checkPlaylistIDs.push(playlist.id)
                    }
                })
            }
        }
    },

    methods: {
        onSave() {

            let newPlaylistNameContent = null
            if(this.newPlaylistShow && this.newPlaylistInputIsActive && this.newPlaylistName) {
                newPlaylistNameContent = this.newPlaylistName
                console.log('newPlaylistNameContent is not null:', newPlaylistNameContent)
            }

            this.isLoading = true
            this.$apollo.mutate({
                mutation: gql`
                    mutation ($trackID: ID!, $playlistsIDs: [ID!]!, $newPlaylistName: String) {
                        addTrackToPlaylists(trackID: $trackID, playlistsIDs: $playlistsIDs, newPlaylistName: $newPlaylistName) {
                            id
                            name

                            tracks {
                                id
                            }
                        }
                    }
                `,
                // Parameters
                variables: {
                    trackID: this.track.id,
                    playlistsIDs: this.checkPlaylistIDs,
                    newPlaylistName: newPlaylistNameContent,
                },
            })
            .then((data) => {
                // Result
                console.log(data.data.addTrackToPlaylists)
                // this.me.playlists = data.data.addTrackToPlaylists;
                // Rerender Component
                // vm.$forceUpdate();
                this.$emit('close')
            }).catch((error) => {
                // Error
                console.error(error)
                this.isLoading = false
            });
        },
    },

    apollo: {
        me: {
            query: gql`
                query {
                    me {
                        playlists {
                            id
                            name

                            tracks {
                                id
                            }
                        }
                    }
                }
            `,
            fetchPolicy: 'cache-and-network',
        }
    }
}
</script>

<style>

</style>